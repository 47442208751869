import * as React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { findPageData } from "../functions";

type IndexPage = any;

const IndexPage = ({ data }: IndexPage) => {
  const pageData = findPageData(data.allMarkdownRemark.edges, "werk");

  return (
    <>
      {/* <Helmet>
        <script src="https://identity.netlify.com/v1/netlify-identity-widget.js"></script>
      </Helmet> */}
      <Layout page={pageData} />
    </>
  );
};

export const query = graphql`
  query werkQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            pageBuilder {
              blocks {
                blockContent
                blockName
              }
              title
              slug
              pageTextColor
              pageTitleColor
              pageBgColor
              showFooter
              showHeader
              metaDescription
              blogOptions
            }
          }
          fileAbsolutePath
        }
      }
    }
  }
`;

export default IndexPage;
